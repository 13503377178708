<template lang="pug">
  .reg-learnRule
    //- product_id = 523188 => 公關合約，應該在'我的專屬方案'頁面以無方案方式呈現，在此判斷僅為防呆作用或之後可設計公關方案專屬呈現方式
    //- template(v-if="this.$route.params.data.product_id === PR_contract_id")
    template
      learn-rule-content(:contract_info="this.$route.params.data")
      agree-learn-rule(:contract_info="this.$route.params.data")

</template>

<script type="text/javascript">
import AgreeLearnRule from '@/components/vip/contracts/agreeLearnRule';
import LearnRuleContent from '@/components/vip/contracts/learnRuleContent';
import contractConstant from '@/constants/contract';

export default {
  name: 'LearnRule',

  components: {
    AgreeLearnRule,
    LearnRuleContent,
  },

  data() {
    return {
      PR_contract_id: contractConstant.PR_PRODUCT_ID,
      permanently_product_id: contractConstant.PERMANENTLY_PRODUCT_ID,
    };
  },

  methods: {
    check_contract(data) {
      const params = {};
      params.coi = data.customer_orders_id;
      this.saveOrderProduct(params)
        .then((response) => {
          this.$router.push({ name: 'contract-list-view' });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
