export default {
  messages: {

    en: {
      agree: 'Agree',
      not_agree_reading_learn_rule: 'I have read and agree to the terms of study.',
      you_have: 'You have',
      read_learn_term_notice: 'Read carefully and agree to the terms of study.',
      agree_success: 'Agreed to study terms successfully.',
      agree_failed: 'Failed to agree to terms.',
    },

    tw: {
      agree: '同意',
      i_agree_reading_learn_rule: '我已閱讀完畢且同意學習條款之內容',
      you_have: '您已於',
      read_learn_term_notice: '審慎閱讀完畢並同意學習條款之內容',
      agree_success: '已同意學習條款成功',
      agree_failed: '同意條款失敗',
    },

  },
};
