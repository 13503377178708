export default {
  messages: {

    en: {
      course_discount: 'Course Discount',
      system_set_fee: 'System Set Fee',
      per_fee: '(Per)',
      dollar: 'Dollar',
      monthly_payment: 'Monthly Payment',
      learn_rule_title: 'Study Agreement Terms And Class Declaration',
      purchase_lesson: 'Purchase Lesson',
      lesson: 'Lesson',
      a_month: 'Month',
      unlimited_lessons: 'Unlimited Lessons',
      lesson_period: 'Lesson Period',
      transfer_session: 'Transfer Session',
      transfer_term: 'Transfer Term',
      gift_session: 'Gift Session',
      gift_term: 'Gift Term',
      total_session: 'Total Session',
      total_term: 'Total Term',
      cash_discount_amount: 'Cash Discount Amount',
      original_contract_period: 'According to the original contract',
    },

    tw: {
      course_discount: '課程優惠價',
      system_set_fee: '系統設定費',
      per_fee: '(乙筆)',
      dollar: '元',
      monthly_payment: '每月月付款',
      learn_rule_title: '學習約定條款與上課聲明',
      purchase_lesson: '購買堂數',
      lesson: '堂',
      a_month: '個月',
      unlimited_lessons: '無限堂',
      lesson_period: '課程期間',
      transfer_session: '移轉堂數',
      transfer_term: '移轉期間',
      gift_session: '贈送堂數',
      gift_term: '贈送期間',
      total_session: '課程總堂數',
      total_term: '總課程期間',
      cash_discount_amount: '現金折扣金額',
      original_contract_period: '依照原合約起訖',
    },

  },
};
