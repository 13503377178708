<template lang="pug">
.wuwow-card
  .wuwow-card-body
    .are-learnRule-content.v-html(v-html='contract_info.contract')
    .are-learnRule-control
      template(v-if="contract_info.learn_rule == '0000-00-00 00:00:00' || !contract_info.learn_rule")
        b-form-checkbox.mr-2(v-model='check_button') {{ $t('i_agree_reading_learn_rule') }}
        br
        button.btn.btn-primary.mt-3(type='button' :disabled='!check_button' @click='check_contract(contract_info.customer_orders_id)') {{ $t('agree') }}
      template(v-else)
        b-alert(variant='info' show='') {{ $t('you_have') }} {{ contract_info.learn_rule }} {{ $t('read_learn_term_notice') }}
        button.btn.btn-outline-primary(type='button' @click='toContractPage()') 下載學習條款

</template>
<script type="text/javascript">
import { mapState } from 'vuex';

import i18n from './lang.js';
import lioshutanApi from '@/api';
import { alertMessage } from '@/utils/sweetAlert.js';
import contractConstant from '@/constants/contract';
import { logout } from '@/composable/auth';

export default {
  name: 'AgreeLearnRule',

  components: {},

  props: {
    // eslint-disable-next-line
    contract_info: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  i18n,

  data() {
    return {
      check_button: false,
    };
  },

  computed: {
    ...mapState({
      contractOwnHistory: (state) => state.vipContract.contractOwnHistory,
    }),
  },

  methods: {
    async check_contract(orderId) {
      const params = {
        coi: orderId,
      };
      try {
        const checkOrderRes = await lioshutanApi.vip.checkOrderProduct(params);
        if (checkOrderRes.message === 'OK') {
          alertMessage('成功', 'success', this.$t('agree_success')).then(() => {
            if (this.isTransferContract()){
              logout();
            }
            this.$router.push({ name: 'contract-list-view' });
          });
        } else {
          alertMessage('失敗', 'error', this.$t('agree_failed'));
        }
      } catch (err) {
        alertMessage('失敗', 'error', this.$t('agree_failed'));
      }
    },
    isTransferContract(){
      return this.contract_info.order_type === contractConstant.CONTRACT_TYPE.TRANSFER_PLATFORM;
    },
    toContractPage() {
      const resolve = this.$router.resolve({
        name: 'program-contract',
        query: {
          page: this.contractOwnHistory.meta.pagination.current_page,
          perPage: this.contractOwnHistory.meta.pagination.per_page,
        },
      });
      window.open(resolve.href, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin checkBoxPosition {
  left: -20px;
  top: 2px;
}
::v-deep .custom-control-label:before{
  @include checkBoxPosition;
}
::v-deep .custom-control-label:after{
  @include checkBoxPosition;
}
</style>

<!-- v-html 內合約樣式 -->
<style>
.v-html ol {
  list-style: decimal;
  margin: revert;
  padding: revert;
}

.v-html ul {
  list-style: disc;
  margin: revert;
  padding: revert;
}

.v-html table {
  width: 100%;
}

.v-html table td {
  border: 1px solid #bfbfbf;
}

.v-html h1 {
  font-size: 24px;
}

.v-html h2 {
  font-size: 21px;
}

.v-html h3 {
  font-size: 16.38px;
}

.v-html h4 {
  font-size: 14px;
}</style>
