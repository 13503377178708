<template lang="pug">
.wuwow-card
  .wuwow-card-body
    h4.h4-learnRule-title {{ $t('learn_rule_title') }}
    p.txt-product-name {{ contract_info.product_name }}
    ul.ul-contract-info
      // product_id = 523234 => 1990月繳型無限堂
      template(v-if='contract_info.product_id == permanently_product_id')
        li.li-contract-info {{ $t('purchase_lesson') }}: {{ $t('unlimited_lessons') }}
        li.li-contract-info {{ $t('total_session') }}: {{ $t('unlimited_lessons') }}
      template(v-else)
        template(v-if="isTransferContract()")
          li.li-contract-info
            | {{ $t('transfer_session') }}: {{ contract_info.gift_session }}{{ $t('lesson') }}
          li.li-contract-info
            | {{ $t('transfer_term') }}: {{ $t('original_contract_period') }}
        template(v-else)
          li.li-contract-info
            | {{ $t('purchase_lesson') }}: {{ contract_info.num_session }}{{ $t('lesson') }}
          li.li-contract-info
            | {{ $t('lesson_period') }}: {{ contract_info.orig_term }}{{ $t('a_month') }}
          li.li-contract-info
            | {{ $t('gift_session') }}: {{ contract_info.gift_session }}{{ $t('lesson') }}
          li.li-contract-info
            | {{ $t('gift_term') }}: {{ contract_info.gift_term }}{{ $t('a_month') }}
          li.li-contract-info
            | {{ $t('total_session') }}: {{ contract_info.total_session }}{{ $t('lesson') }}
          li.li-contract-info
            | {{ $t('total_term') }}: {{ contract_info.total_term }}{{ $t('a_month') }}
          li.li-contract-info
            | {{ $t('cash_discount_amount') }}: ${{
            | Number(contract_info.bd_quota) +
            | Number(contract_info.one_pay_disc_price) +
            | Number(contract_info.disc_price) +
            | Number(contract_info.other_disc) +
            | Number(contract_info.renewal_disc)
            | }}
    // o2o => 實體補習班合作方案
    template(v-if='contract_info.o2o !== 1 && !isTransferContract()')
      template(v-if='contract_info.product_id == permanently_product_id')
        p.txt-product-price
          | {{ $t('course_discount') }} {{ $t('system_set_fee') }}{{ $t('per_fee') }} $3000 {{ $t('dollar') }} + {{ $t('monthly_payment') }}$1990 {{ $t('dollar') }}
      template(v-else)
        p.txt-product-price
          | {{ $t('course_discount') }} ${{ contract_info.selling_price }}
</template>
<script type="text/javascript">
import i18n from './lang.js';
import contractConstant from '@/constants/contract';
import { isObjectEmpty } from '@/utils/verify.js';

export default {
  name: 'LearnRuleContent',

  components: {},

  props: {
    // eslint-disable-next-line
    contract_info: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  i18n,

  data() {
    return {
      permanently_product_id: contractConstant.PERMANENTLY_PRODUCT_ID,
    };
  },

  created() {
    if (this.contractInfoIsEmpty()){
      this.$router.push({ name: 'contract-list-view' });
    }
  },

  methods: {
    contractInfoIsEmpty(){
      return isObjectEmpty(this.contract_info);
    },
    isTransferContract(){
      return this.contract_info.order_type === contractConstant.CONTRACT_TYPE.TRANSFER_PLATFORM;
    },
  },
};
</script>
